<template>
  <div class="course-container">
    <h3 style="text-align: left;">课程列表</h3>
    <div style="width: 100%;padding-bottom: 15px;box-sizing: border-box;overflow-y: auto">
      <div v-for="(schedule,index) in dataList" class="schedule-item" :key="index"
           @click="gotCourseInfo(schedule.course_id)">
        <div class="item-top" :style="formatItemBg(index)">
          <img src="../../../assets/icon_schedule.png" style="width: 20px;height: 20px">
          <p style="margin-left: 15px">{{ schedule.show_start_time }}</p>
          <p style="flex: 1;text-align: right">{{ schedule.show_week }}</p>
        </div>
        <div style="margin-top: 8px">
          <div v-for="(song,childIndex) in schedule.songs" :key="childIndex" class="song-item">
            <p class="point"/>
            <p class="song-name">{{ song.song_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getUserAllCourses} from '../../../api/keneng'
import {formatDate} from "../../../utils/date";

export default {
  name: "CourseList",
  data() {
    return {
      user_id: this.$route.query.user_id,
      dataList: []
    }
  },
  mounted() {
    if (false) {
      this.user_id = '5ee9b932a4640bdc378b456b'
    }
    this.fetchData()
  },
  methods: {

    gotCourseInfo(course_id) {
      this.$router.push({
        path: '/course',
        query: {
          id: course_id
        }
      })
    },

    formatItemBg(index) {
      let back = {}
      if (index % 2 == 0) {
        back.backgroundColor = '#FFAF7E'
      } else {
        back.backgroundColor = '#9894E5'
      }
      return back
    },

    fetchData() {
      getUserAllCourses(this.user_id).then((res) => {
        let result = res.data
        this.dataList = result.map((item) => {
          let start_time = item.course.start_time
          let date = new Date(start_time * 1000)
          item.course_id = item.course._id.$id
          item.show_start_time = formatDate(date, 'yyyy-MM-dd')
          item.show_week = this.formatWeek(date.getDay())
          return item
        })
      })
    },
    formatWeek(day) {
      switch (day) {
        case 0:
          return '星期日'
        case 1:
          return '星期一'
        case 2:
          return '星期二'
        case 3:
          return '星期三'
        case 4:
          return '星期四'
        case 5:
          return '星期五'
        case 6:
          return '星期六'
      }
    }
  }
}
</script>

<style scoped lang="less">

.point {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #999999
}

.song-name {
  margin-left: 12px;
  font-size: 16px;
  font-weight: normal;
  color: #666666;
  line-height: 24px;
}

.song-item {
  padding: 0px 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.schedule-item {
  cursor: pointer;
  margin-bottom: 15px;
  background: #FFFFFF;
  box-shadow: 0px 3px 15px 1px rgba(93, 91, 181, 0.16);
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 5px;
  border-radius: 6px;
}

.item-top {
  color: white;
  font-family: "PingFang SC-Regular";
  padding: 8px 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.course-container {
  background-color: white;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}


</style>
